import React from 'react';

function NotFoundPage(): JSX.Element {
  return (
    <div>
      404 Not Found
    </div>
  );
}

export default NotFoundPage;
