import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MetronomePage from '../MetronomePage';
import TunerPage from '../TunerPage';
import NotFoundPage from '../NotFoundPage';

function DashboardRoutes(): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Redirect
            to="/metronome"
            data-test-id="redirect--root"
          />
        )}
        data-test-id="route--root"
      />
      <Route
        exact
        path="/metronome"
        component={MetronomePage}
        data-test-id="route--metronome"
      />
      <Route
        exact
        path="/tuner"
        component={TunerPage}
        data-test-id="route--tuner"
      />
      <Route
        component={NotFoundPage}
        data-test-id="route--not-found"
      />
    </Switch>
  );
}

export default DashboardRoutes;
