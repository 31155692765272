import React, { useState } from 'react';
import {
  Button,
  Form,
  Slider,
  InputNumber,
} from 'antd';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import Metronome from '../Metronome';
import './styles.scss';

function MetronomePage(): JSX.Element {
  const MIN_BPM = 35;
  const MAX_BPM = 250;
  const UPS = 120;
  const FPS = 60;
  const [playing, setPlaying] = useState(false);
  const [bpm, setBpm] = useState(120);
  return (
    <div className="MetronomePage">
      <Metronome
        minBpm={MIN_BPM}
        maxBpm={MAX_BPM}
        updatesPerSecond={UPS}
        framesPerSecond={FPS}
        bpm={bpm}
        playing={playing}
        data-test-id="metronome"
      />
      <Form className="form">
        <Form.Item label="Beats per minute">
          <div className="bpm">
            <Slider
              className="slider"
              min={MIN_BPM}
              max={MAX_BPM}
              onChange={(value: number) => setBpm(value)}
              value={bpm}
              data-test-id="bpm--slider"
            />
            <InputNumber
              className="number"
              min={MIN_BPM}
              max={MAX_BPM}
              style={{ margin: '0 16px' }}
              value={bpm}
              onChange={(value: string | number | undefined) => {
                if (typeof value === 'number') {
                  setBpm(value);
                }
              }}
              type="number"
              data-test-id="bpm--input"
            />
          </div>
        </Form.Item>
        <Button
          type="primary"
          shape="circle"
          icon={(playing
            ? <PauseOutlined data-test-id="icon--pause" />
            : <CaretRightOutlined data-test-id="icon--play" />
          )}
          onClick={() => setPlaying(!playing)}
          size="large"
          data-test-id="play-button"
        />
      </Form>
    </div>
  );
}

export default MetronomePage;
