import React, { useState } from 'react';
import {
  Button,
  Grid,
  Layout,
  Typography,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import DashboardMenu from '../DashboardMenu';
import DashboardRoutes from '../DashboardRoutes';
import './styles.scss';

const { useBreakpoint } = Grid;

function Dashboard(): JSX.Element {
  const [collapsed, setCollapsed] = useState(true);
  const screens = useBreakpoint();

  function onSelectMenuItem() {
    if (!screens.md) {
      setCollapsed(true);
    }
  }

  return (
    <Layout className="Dashboard">
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={0}
        className="sider site-layout-background"
        data-test-id="drawer"
      >
        <Layout.Header className="spacer" />
        <DashboardMenu
          onSelect={onSelectMenuItem}
          data-test-id="menu"
        />
      </Layout.Sider>
      <Layout>
        <Layout.Header className="header">
          <Button
            size="large"
            type="link"
            icon={<MenuOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            data-test-id="hamburger"
          />
          <Typography.Title level={3} className="title">True Music</Typography.Title>
        </Layout.Header>
        <Layout.Content className="content site-layout-background">
          <DashboardRoutes />
        </Layout.Content>
        <Layout.Footer className="footer">
          <Typography>
            True Music v
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
