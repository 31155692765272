import React from 'react';
import Tuner from '../Tuner';

function TunerPage(): JSX.Element {
  return (
    <Tuner />
  );
}

export default TunerPage;
