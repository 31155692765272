import React, { useEffect, useRef } from 'react';
import { useAudio } from '../../hooks';
import { Props } from './types';
import './styles.scss';

function Metronome(props: Props): JSX.Element {
  const {
    minBpm,
    maxBpm,
    updatesPerSecond,
    framesPerSecond,
    bpm,
    playing,
  } = props;
  const stickRef = useRef<HTMLDivElement>(null);
  const weightRef = useRef<HTMLDivElement>(null);
  const tick = useAudio('tick.mp3');

  useEffect(() => {
    const timeLimit = ((1000 * 60) / bpm);
    let now = Date.now();
    let start = now;
    let delta = 0;
    let hasTicked = false;
    let direction = 1;

    function draw(): void {
      if (stickRef.current && weightRef.current) {
        const percent = (delta / timeLimit);
        const stickAngle = (percent * 90 - 45) * direction;
        const stickStyles = getComputedStyle(stickRef.current);
        const stickHeight = parseInt(stickStyles.height, 10);
        const weightYPosition = ((bpm - minBpm / 2) / maxBpm) * stickHeight;
        stickRef.current.style.transform = `rotate(${stickAngle}deg)`;
        weightRef.current.style.transform = `translateX(-25%) translateY(${weightYPosition}px)`;
      }
    }

    function update(): void {
      if (playing) {
        now = Date.now();
        delta = now - start;
        if (!hasTicked && delta >= timeLimit / 2) {
          tick.play();
          hasTicked = true;
        }
        if (delta >= timeLimit) {
          start = now - (delta % timeLimit);
          delta = 0;
          hasTicked = false;
          direction = -direction;
        }
      }
    }

    const updateInterval = setInterval(update, 1000 / updatesPerSecond);
    const drawInterval = setInterval(() => requestAnimationFrame(draw), 1000 / framesPerSecond);
    return () => {
      clearInterval(updateInterval);
      clearInterval(drawInterval);
    };
  }, [bpm, playing]);

  return (
    <div className="Metronome">
      <div className="container">
        <div className="body" />
        <div className="cutout" />
        <div className="stick" ref={stickRef}>
          <div className="weight" ref={weightRef} />
        </div>
        <div className="cover" />
      </div>
    </div>
  );
}

export default Metronome;
