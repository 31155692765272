import React, { useState, useEffect } from 'react';
import { Button, Typography } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import PitchAnalyser from 'pitch-analyser';
import './styles.scss';

function Tuner(): JSX.Element {
  const FPS = 24;
  const [isOn, setIsOn] = useState(false);
  const [pitch, setPitch] = useState('');
  const [frequency, setFrequency] = useState(0);
  const [cents, setCents] = useState(0);
  const needleAngle = (cents / 10000) * 90;

  useEffect(() => {
    let now = Date.now();
    let then = now;
    const interval = 1000 / FPS;
    function callback(payload: {
      note: string;
      frequency: number;
      cents: number;
    }): void {
      now = Date.now();
      const delta = now - then;
      if (delta >= interval) {
        then = now - (delta % interval);
        setPitch(payload.note);
        setFrequency(payload.frequency);
        setCents(payload.cents);
      }
    }
    if (isOn) {
      const pitchAnalyzer = new PitchAnalyser({ callback, returnCents: true });
      pitchAnalyzer.initAnalyser(
        () => pitchAnalyzer.startAnalyser(
          () => pitchAnalyzer.resumeAnalyser(),
        ),
      );
      return () => {
        pitchAnalyzer.stopAnalyser();
        setPitch('');
        setFrequency(0);
        setCents(0);
      };
    }
    return () => { /* Do nothing */ };
  }, [isOn]);

  return (
    <div className="Tuner">
      <div className="window">
        <div className="gauge">
          <div
            className="needle"
            style={{ transform: `translateY(25%) rotate(${needleAngle}deg)` }}
            data-test-id="needle"
          />
        </div>
      </div>
      <div className="pitch-display">
        <Typography.Title
          className="pitch"
          level={3}
          data-test-id="pitch"
        >
          {isOn && pitch}
        </Typography.Title>
      </div>
      <div className="frequency-display">
        <Typography.Title
          className="frequency"
          level={3}
          data-test-id="frequency"
        >
          {isOn && `${frequency}hz`}
        </Typography.Title>
      </div>
      <div className="power-area">
        <div className={`light ${isOn ? 'on' : 'off'}`} data-test-id="light" />
        <Button
          type="link"
          size="large"
          icon={<PoweroffOutlined />}
          className="power-button"
          onClick={() => setIsOn(!isOn)}
          data-test-id="power-button"
        />
      </div>
    </div>
  );
}

export default Tuner;
