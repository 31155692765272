import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { ClockCircleOutlined, DashboardOutlined } from '@ant-design/icons';
import { Props } from './types';
import './styles.scss';

function DashboardMenu(props: Props): JSX.Element {
  const { onSelect } = props;
  return (
    <Menu
      defaultSelectedKeys={[window.location.pathname]}
      theme="dark"
      mode="inline"
      className="DashboardMenu"
    >
      <Menu.Item
        title={null}
        key="/metronome"
        icon={<ClockCircleOutlined />}
        onClick={onSelect}
        data-test-id="menu-item--metronome"
      >
        <Link to="/metronome">Metronome</Link>
      </Menu.Item>
      <Menu.Item
        title={null}
        key="/tuner"
        icon={<DashboardOutlined />}
        onClick={onSelect}
        data-test-id="menu-item--tuner"
      >
        <Link to="/tuner">Tuner</Link>
      </Menu.Item>
    </Menu>
  );
}

export default DashboardMenu;
